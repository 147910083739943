import { APP_INITIALIZER, ApplicationConfig, ErrorHandler, LOCALE_ID } from '@angular/core';
import { provideRouter, Router, withComponentInputBinding } from '@angular/router';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import localeFrCH from '@angular/common/locales/fr-CH';
import localeFrCHExtra from '@angular/common/locales/extra/fr-CH';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { registerLocaleData } from '@angular/common';
import { MAT_DATE_FNS_FORMATS, provideDateFnsAdapter } from '@angular/material-date-fns-adapter';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MaterialCustomDateAdapter } from './shared/material/material-custom-date-adapter';
import { MaterialCustomPaginatorIntl } from './shared/material/material-custom-paginator-intl';
import { frCH } from 'date-fns/locale';
import { loadingInterceptor } from './shared/interceptor/loading.interceptor';
import * as Sentry from "@sentry/angular-ivy";
import { contentTypeInterceptor } from './shared/interceptor/content-type.interceptor';

registerLocaleData(localeFrCH, 'fr-CH', localeFrCHExtra);

function SentryProviders() {
  return [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ];
}

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: LOCALE_ID, useValue: 'fr-CH' },
    { provide: MAT_DATE_LOCALE, useValue: frCH },
    provideDateFnsAdapter({
      ...MAT_DATE_FNS_FORMATS,
      parse: {
        dateInput: ["dd.MM.yyyy"],
      },
    }),
    { provide: DateAdapter, useClass: MaterialCustomDateAdapter },
    { provide: MatPaginatorIntl, useClass: MaterialCustomPaginatorIntl },
    provideRouter(routes, withComponentInputBinding()),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptors([loadingInterceptor, contentTypeInterceptor])),
    ...SentryProviders(),
  ],
};


