import { ChangeDetectionStrategy, Component, inject, viewChild } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { LoadingService } from '../shared/services/loading.service';
import { LossReportFormDialogComponent } from '../loss-report/loss-report-form-dialog.component';
import { MatDialog, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { environment } from '../../environments/environment';
import { JsonPipe } from '@angular/common';
import { versionEnv } from '../../environments/version';


@Component({
  selector: 'app-header',
  standalone: true,
  imports: [MatToolbarModule, MatButtonModule, MatIconModule, MatDialogContent, MatDialogTitle, JsonPipe],
  template: `
    <mat-toolbar class="h-full">
      <div class="h-full container mx-auto justify-center items-center md:justify-between flex">
        <img
          (mousedown)="startPress('mouse', $event)"
          (mouseup)="cancelPress('mouse', $event)"
          (mouseleave)="cancelPress('mouse', $event)"
          (touchstart)="startPress('touch', $event)"
          (touchend)="cancelPress('touch', $event)"
          (touchcancel)="cancelPress('touch', $event)"
          src="assets/header-logo.png" alt="logo" class="h-full"/>
        <button mat-flat-button color="primary" class="!hidden md:!flex" [disabled]="loadingService.isLoading()"
                (click)="openDialog()">
          <mat-icon fontSet="material-icons-outlined">front_hand</mat-icon>
          Déclarer un objet perdu
        </button>
      </div>
    </mat-toolbar>

    <ng-template #debugDialog>
      <div mat-dialog-title>Informations de debug</div>
      <mat-dialog-content>
        <pre>{{ debugInfo | json }}</pre>
      </mat-dialog-content>
    </ng-template>
  `,
  styles: `
    :host {
      display: block;
    }

    .spacer {
      flex: 1 1 auto;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  debugDialog = viewChild('debugDialog');
  loadingService = inject(LoadingService);
  readonly debugInfo = { ...environment, ...versionEnv };

  private dialog = inject(MatDialog);
  private pressTimer: any;
  private pressDuration: number = 3000; // Durée en millisecondes

  openDialog(): void {
    this.dialog.open(LossReportFormDialogComponent, {
      autoFocus: false
    });
  }

  showDebugDialog(): void {
    this.dialog.open(this.debugDialog()! as any, {
      autoFocus: false
    });
  }


  startPress(type: string, event: MouseEvent | TouchEvent): void {
    console.log('startPress')
    event.preventDefault(); // Prévenir le comportement par défaut comme le scrolling
    this.pressTimer = setTimeout(() => this.longPressActivated(type), this.pressDuration);
  }

  cancelPress(type: string, event: MouseEvent | TouchEvent): void {
    console.log('cancelPress')
    clearTimeout(this.pressTimer); // Annuler le compteur si le curseur ou le doigt quitte l'élément
  }

  longPressActivated(type: string): void {
    this.showDebugDialog();
  }
}
