import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, effect, inject, Input as RouteInput, OnInit, signal } from '@angular/core';
import { HeaderComponent } from "./header.component";
import { FoundItemTableComponent } from "./found-item-table/found-item-table.component";
import { MatButton } from '@angular/material/button';
import { LoadingService } from '../shared/services/loading.service';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { LossReportFormDialogComponent } from '../loss-report/loss-report-form-dialog.component';
import { FoundItemFilters } from './found-item-table/found-item-filters.model';
import { FoundItemStore } from './found-item.store';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-home',
  standalone: true,
  template: `
    <app-header/>
    <div class="container flex overflow-hidden flex-col h-full py-4 px-2 md:px-0 gap-4 mx-auto">
      <app-found-item-table [(filters)]="foundItemStore.state.filters" class="overflow-hidden h-full block relative"/>

      <button class="h-9 md:!hidden" mat-flat-button color="primary" [disabled]="loadingService.isLoading()"
              (click)="openDialog()">
        <mat-icon fontSet="material-icons-outlined">front_hand</mat-icon>
        Déclarer un objet perdu
      </button>
    </div>
  `,
  styles: `
    :host {
      display: flex;
      flex-direction: column;
      min-height: 100%;
      overflow: hidden;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    HeaderComponent,
    FoundItemTableComponent,
    MatButton,
    MatIconModule
  ]
})
export class HomeComponent implements OnInit {
  loadingService = inject(LoadingService);
  @RouteInput() category?: string;
  @RouteInput() date?: string;
  @RouteInput() color?: string;
  @RouteInput() line?: string;
  foundItemStore = inject(FoundItemStore);

  private dialog = inject(MatDialog);
  private router = inject(Router);
  private route = inject(ActivatedRoute);

  constructor() {
    // @ts-ignore
    effect(() => {
      const filters = this.foundItemStore.state.filters();
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { ...filters, date: filters.date?.toISOString() },
        replaceUrl: true
      });
    });
  }

  ngOnInit() {
    this.foundItemStore.state.filters.set({
      category: this.category !== undefined ? Number.parseInt(this.category) : undefined,
      color: this.color,
      date: this.date ? new Date(this.date) : undefined,
      line: this.line,
    });
  }

  openDialog(): void {
    this.dialog.open(LossReportFormDialogComponent, {
      autoFocus: false
    });
  }
}
