import { Directive, ElementRef, EventEmitter, HostListener, inject, Output, Renderer2 } from '@angular/core';

/**
 * Fichier similaire avec l'autre app front
 */
@Directive({
  selector: '[appDragDrop]',
  standalone: true
})
export class DragDropDirective {
  private renderer = inject(Renderer2);
  private el = inject(ElementRef);

  @Output() onFileDropped = new EventEmitter<Event>();

  // Dragover listener
  @HostListener('dragover', ['$event']) onDragOver(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.renderer.addClass(this.el.nativeElement, '!bg-blue-100');
    this.renderer.addClass(this.el.nativeElement, '!border-blue-500');
    this.renderer.addClass(this.el.nativeElement, '!text-blue-500');
  }

  // Dragend listener
  @HostListener('dragend', ['$event']) onDragEnd(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.renderer.removeClass(this.el.nativeElement, '!bg-blue-100');
    this.renderer.removeClass(this.el.nativeElement, '!border-blue-500');
    this.renderer.removeClass(this.el.nativeElement, '!text-blue-500');
  }

  // Drop listener
  @HostListener('drop', ['$event']) onDrop(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.renderer.removeClass(this.el.nativeElement, '!bg-blue-100');
    this.renderer.removeClass(this.el.nativeElement, '!border-blue-500');
    this.renderer.removeClass(this.el.nativeElement, '!text-blue-500');
    if (evt.dataTransfer) {
      const files = evt.dataTransfer.files;
      if (files.length > 0) {
        const validFiles = Array.from(files).filter(file =>
          file.type === 'image/jpeg' ||
          file.type === 'image/png' ||
          (file.type === 'image/jpg' && file.name.endsWith('.jpg'))
        );
        if (validFiles.length > 0) {
          const validFileEvent = { target: { files: validFiles }} as unknown as Event;
          this.onFileDropped.emit(validFileEvent);
        } else {
          alert('Seuls les fichiers JPG, JPEG et PNG sont acceptés.');
        }
      }
    }
  }

  // Optionnel : Ajoutez dragleave pour gérer les sorties qui ne terminent pas par un drop
  @HostListener('dragleave', ['$event']) onDragLeave(evt: DragEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    this.renderer.removeClass(this.el.nativeElement, '!bg-blue-100');
    this.renderer.removeClass(this.el.nativeElement, '!border-blue-500');
    this.renderer.removeClass(this.el.nativeElement, '!text-blue-500');
  }
}
