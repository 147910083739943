import { Injectable, Signal, signal } from '@angular/core';
import { FoundItemFilters } from './found-item-table/found-item-filters.model';

@Injectable({
  providedIn: 'root'
})
export class FoundItemStore {
  private initialFilters: FoundItemFilters = {};
  state = {
    filters: signal<FoundItemFilters>(this.initialFilters)
  }
}
