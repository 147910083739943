export class ObjectHelper {
  static removeUndefinedProperties(obj: { [key: string]: any | undefined }): { [key: string]: any } {
    try {
      return Object.entries(obj as any).reduce((acc, [key, value]) => {
        if (value !== null && value !== undefined && value !== '') {
          (acc as any)[key] = value;
        }
        return acc;
      }, {});
    } catch (error) {
      console.error('Cannot remove undefined properties:', error);
      return {};
    }
  }
}
