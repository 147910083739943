import { DataSource } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { catchError, debounceTime, map, startWith, switchMap, tap } from 'rxjs/operators';
import { combineLatest, merge, Observable, of as observableOf, Subject } from 'rxjs';
import { Category } from '../../models/category.model';
import { Injector, Signal, signal } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FoundItemService } from '../found-item.service';
import { toObservable } from '@angular/core/rxjs-interop';
import { FoundItem } from '../../models/found-item.model';
import { FoundItemFilters } from './found-item-filters.model';

export interface FoundItemTableItem {
  id: string;
  category: Category;
  status: string;
  hasLossReport: boolean;
  date: Date;
  color: string;
}

/**
 * Data source for the FoundItemTable view. This class should
 * encapsulate all logic for fetching and manipulating the displayed data
 * (including sorting, pagination, and filtering).
 */
export class FoundItemTableDataSource extends DataSource<FoundItemTableItem> {
  paginator: MatPaginator | undefined;
  sort: MatSort | undefined;
  isLoading = signal(true);
  rawData = signal<FoundItem[]>([]);
  data = signal<FoundItemTableItem[]>([]);
  private refreshSubject = new Subject<void>();

  constructor(private injectorRef: Injector,
              private filters: Signal<FoundItemFilters>,
              private foundItemService: FoundItemService,
              private snackBar: MatSnackBar) {
    super();
  }

  /**
   * Connect this data source to the table. The table will only update when
   * the returned stream emits new items.
   * @returns A stream of the items to be rendered.
   */
  connect(): Observable<FoundItemTableItem[]> {
    if (!this.paginator || !this.sort || !this.filters) {
      throw Error('Please set the paginator, sort and filters on the data source before connecting.');
    }
    const filters$ = toObservable(this.filters, { injector: this.injectorRef }).pipe(
      tap((value) => console.debug('filters datasource', value)),
      tap(() => this.paginator?.firstPage())
    );
    const paginationAndRefresh$ = merge(this.paginator.page, this.refreshSubject, filters$).pipe(
      debounceTime(200),
      tap(() => this.isLoading.set(true)),
      switchMap(() =>
        this.foundItemService.findAllAtPage(this.paginator!.pageIndex, this.paginator!.pageSize, this.filters!()).pipe(
          catchError((error) => {
            this.snackBar.open(error.message, 'Fermer');
            this.isLoading.set(false);
            return observableOf({ items: [], totalItems: 0 });
          })
        )
      ),
      map(({ items, totalItems }) => {
        this.paginator!.length = totalItems;
        this.rawData.set(items);
        return this.mapData(items);
      }),
      tap(data => {
        console.debug('paginationAndRefresh$', data)
        this.data.set(data);
        this.isLoading.set(false);
      }),
      catchError(() => {
        this.isLoading.set(false);
        return observableOf([]);
      })
    );

    return combineLatest([paginationAndRefresh$, this.sort.sortChange.pipe(startWith({}))]).pipe(
      map(([data, _]) => this.getSortedData(data))
    );

  }

  /**
   *  Called when the table is being destroyed. Use this function, to clean up
   * any open connections or free any held resources that were set up during connect.
   */
  disconnect(): void {
  }

  refresh(): void {
    this.refreshSubject.next();
  }

  private mapData(data: FoundItem[]): FoundItemTableItem[] {
    return data.map((foundItem) => {
      return {
        id: foundItem.id,
        category: foundItem.category ?? { name: 'Inconnu', id: 0 },
        status: foundItem.hasLossReport ? 'Réservé' : 'Ouvert',
        hasLossReport: foundItem.hasLossReport,
        date: foundItem.foundDate,
        color: foundItem.color?.label ?? ''
      } as FoundItemTableItem;
    });
  }

  private getSortedData(data: FoundItemTableItem[]): FoundItemTableItem[] {
    if (!this.sort || !this.sort.active || this.sort.direction === '') {
      return data;
    }

    return data.sort((a, b) => {
      const isAsc = this.sort!.direction === 'asc';
      switch (this.sort!.active) {
        case 'id':
          return compare(a.id, b.id, isAsc);
        case 'status':
          return compare(a.status, b.status, isAsc);
        case 'category':
          return compare(a.category.name, b.category.name, isAsc); // Assuming category comparison by name
        case 'color':
          return compare(a.color, b.color, isAsc);
        case 'date':
          return compare(a.date.toString(), b.date.toString(), isAsc);
        default:
          return 0;
      }
    });
  }
}

/** Simple sort comparator for example ID/Name columns (for client-side sorting). */
function compare(a: string | number, b: string | number, isAsc: boolean): number {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
