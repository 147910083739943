export enum FoundItemStatus {
  FOUND = 'FOUND',
  DEPOSITED = 'DEPOSITED',
  ANALYZING = 'ANALYZING',
  WAITING_IDENTIFICATION = 'WAITING_IDENTIFICATION',
  WAITING_CUSTOMER_VERIFICATION = 'WAITING_CUSTOMER_VERIFICATION',
  WAITING_TL_VERIFICATION = 'WAITING_TL_VERIFICATION',
  WAITING_ON_RESTITUTION = 'WAITING_ON_RESTITUTION',
  RETURNED = 'RETURNED',
}
