/// <reference types="@angular/localize" />

import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import * as Sentry from "@sentry/angular-ivy";
import { versionEnv } from './environments/version';
import { environment } from './environments/environment';
import { httpClientIntegration } from '@sentry/integrations';

Sentry.init({
  dsn: "https://ea550a3acd1159cde124da19983fd0f3@o311737.ingest.us.sentry.io/4507060438302720",
  integrations: [httpClientIntegration({
    failedRequestStatusCodes: [[400, 599]],
  })],
  release: `claiming@${versionEnv.version}`,
  dist: versionEnv.commitHash,
  enabled: environment.enableSentry,
  environment: environment.envName,
  beforeBreadcrumb(breadcrumb) {
    if (breadcrumb.level === "debug" && breadcrumb.category === "console") return null;
    return breadcrumb;
  },
});

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));
