import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LossReportForm } from './loss-report-form.model';
import { environment } from '../../environments/environment';
import { catchError, Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { FoundItem } from '../models/found-item.model';

@Injectable({
  providedIn: 'root'
})
export class LossReportService {
  private http = inject(HttpClient);

  createLossReport(lossReport: LossReportForm, foundItem?: FoundItem): Observable<string> {
    let body = {
      categoryId: `${lossReport.subcategory}`,
      color: lossReport.color,
      contactPreferences: lossReport.contactPreferences,
      description: lossReport.description,
      email: lossReport.email,
      firstname: lossReport.firstname,
      lastname: lossReport.lastname,
      lossDate: lossReport.lossDate,
      phone: lossReport.phone,
    };
    if (!!lossReport.line) {
      body = {
        ...body,
        line: {
          id: lossReport.line.id,
          direction: lossReport.line.direction
        }
      } as any;
    }
    if (!!foundItem) {
      Object.assign(body, {
        assignedFoundItemId: foundItem.id,
      });
    }
    const formData: FormData = new FormData();
    formData.append('createLossReportDto', JSON.stringify(body));
    lossReport.identityDocuments?.forEach(file => {
      formData.append('identityDocuments', file);
    });

    return this.http.post<{ lossReportCaseId: string }>(`${environment.apiUrl}/public/loss-reports`, formData)
      .pipe(
        map((response => response.lossReportCaseId)),
        catchError((error) => {
          console.error('Error handling:', error);
          return throwError(() => new Error('Une erreur est survenue lors de création de la déclaration de perte.'));
        })
      );
  }
}
