import { Pipe, PipeTransform } from '@angular/core';
import { Category } from '../../models/category.model';

@Pipe({
  name: 'category',
  standalone: true
})
export class CategoryPipe implements PipeTransform {

  transform(category: Category | undefined, format: string = 'full'): string {
    if (!category) return '';
    switch (format) {
      case 'short':
        return category.name;
      case 'full':
      default:
        return this.formatCategoryName(category);
    }
  }

  private formatCategoryName(category: Category): string {
    let formattedName = category.name.charAt(0).toUpperCase() + category.name.slice(1).toLowerCase();
    if (category.subcategories && category.subcategories.length) {
      const subcategoriesNames = category.subcategories.map(subcategory => this.formatCategoryName(subcategory)).join(' > ');
      return `${formattedName} > ${subcategoriesNames}`;
    } else {
      return formattedName;
    }
  }
}
