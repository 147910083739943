import { AbstractControl, ValidatorFn } from '@angular/forms';
import { differenceInYears, isValid } from 'date-fns';

export function customDateValidator(): ValidatorFn {
  return (control: AbstractControl<Date>): { [key: string]: any } | null => {
    if(!control.value) return null;

    // Si la valeur n'est pas définie ou n'est pas une instance de Date, retourner une erreur
    if (isNaN(control.value.getTime())) {
      return { 'invalidDate': { value: control.value } };
    }

    const inputDate = control.value;
    if (!isValid(inputDate)) {
      return { 'invalidDate': { value: control.value } };
    }

    const today = new Date();
    const yearsDifference = differenceInYears(today, inputDate);

    if (yearsDifference > 125) {
      return { 'dateTooOld': { value: control.value } };
    }
    return null;
  };
}
