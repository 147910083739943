import { ChangeDetectionStrategy, Component, Inject, inject, signal, viewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateMaskDirective } from '../shared/directives/date-mask.directive';
import { MatCardModule } from '@angular/material/card';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { LossReportFormComponent } from './loss-report-form.component';
import { LossReportService } from './loss-report.service';
import { LossReportForm } from './loss-report-form.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { finalize, take } from 'rxjs';
import { FoundItem } from '../models/found-item.model';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { LoadingService } from '../shared/services/loading.service';

@Component({
  selector: 'app-loss-report-form-dialog',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatDialogModule,
    MatDatepickerModule,
    MatButtonModule,
    MatCheckboxModule,
    DateMaskDirective,
    MatCardModule,
    ReactiveFormsModule,
    MatSelectModule,
    LossReportFormComponent,
    MatProgressSpinner
  ],
  template: `
    <button mat-mini-fab mat-dialog-close color="primary" class="!absolute top-2 right-2 z-10">
      <mat-icon>close</mat-icon>
    </button>
    <div mat-dialog-title>
      <h1 class="m-0">Déclaration de perte</h1>
    </div>

    <mat-dialog-content class="!overflow-x-hidden">
      <app-loss-report-form #formComponent [foundItem]="foundItem"/>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-flat-button color="primary"
              [disabled]="formComponent.lossReportForm.invalid || creatingLossReport() || loadingService.isLoading()"
              (click)="createLossReport(formComponent.getRawValue()!)">
        <div class="h-full flex justify-center items-center gap-2">
          @if (creatingLossReport()) {
            <mat-spinner [diameter]="25"/>
          } @else {
            <mat-icon>check</mat-icon>
          }
          Déclarer l'objet perdu
        </div>

      </button>
    </mat-dialog-actions>

    <ng-template #successDialog>
      <div class="flex justify-center items-center bg-green-200 rounded-full h-28 w-28 mx-auto mt-8 text-green-500">
        <mat-icon>check</mat-icon>
      </div>
      <mat-dialog-content>
        <h2 mat-dialog-title class="!px-0 text-center">
          Votre déclaration nous a bien été transmise
          <div class="text-sm font-medium">ID de la demande #{{ lossReportCreatedCaseId }}</div>
        </h2>

        <div class="text-center">
          Vous recevrez bientôt un e-mail récapitulatif de votre déclaration de perte, si nous trouvons un objet qui
          pourrait correspondre, nous vous recontacterons dans les plus brefs délais pour établir un rendez vous.
        </div>
      </mat-dialog-content>
      <mat-dialog-actions align="end">
        <button mat-button mat-dialog-close>J'ai compris</button>
      </mat-dialog-actions>
    </ng-template>
  `,
  styles: ``,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LossReportFormDialogComponent {
  private lossReportService = inject(LossReportService);
  private snackBar = inject(MatSnackBar);
  private lossReportForm = viewChild(LossReportFormComponent);
  private successDialog = viewChild('successDialog');
  private dialog = inject(MatDialog);
  private breakpointObserver = inject(BreakpointObserver);
  lossReportCreatedCaseId: string | null = null;
  creatingLossReport = signal(false);
  loadingService = inject(LoadingService);

  constructor(
    private dialogRef: MatDialogRef<LossReportFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public foundItem: FoundItem | undefined
  ) {
    if (this.breakpointObserver.isMatched([Breakpoints.XSmall, Breakpoints.Small])) {
      this.dialogRef.addPanelClass('custom-small-dialog');
      this.dialogRef.updateSize('100%', '100%');
      this.dialogRef.updatePosition({ top: '0', left: '0', bottom: '0', right: '0' });
    }
    this.dialogRef.disableClose = true;
  }

  createLossReport(formValue: LossReportForm) {
    this.lossReportForm()!.lossReportForm.disable();
    this.creatingLossReport.set(true);
    this.lossReportService.createLossReport(formValue, this.foundItem)
      .pipe(
        take(1),
        finalize(() => {
          this.creatingLossReport.set(false);
          this.lossReportForm()?.lossReportForm.enable();
        })
      )
      .subscribe({
        next: (id) => {
          this.lossReportCreatedCaseId = id;
          const successDialog = this.dialog.open(this.successDialog()! as any, {
            disableClose: true,
          });
          successDialog.afterClosed().pipe(take(1)).subscribe({
            next: () => {
              this.dialogRef.close();
            }
          });
        },
        error: (error) => {
          this.snackBar.open(error.message, 'Fermer', { politeness: 'assertive' });
        }
      });
  }
}
