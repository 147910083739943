import { AbstractControl, ValidatorFn } from '@angular/forms';

export function customPhoneValidator(): ValidatorFn {
  return (control: AbstractControl<string>): { [key: string]: any } | null => {
    if (!control.value) {
      return null;
    }
    // Cette expression régulière couvre les cas suivants :
    // +33 x xx xx xx xx avec ou sans espace
    // xx xx xx xx xx avec ou sans espace
    // +41 xx xxx xx xx avec ou sans espace
    // xxx xxx xx xx avec ou sans espaces
    const valid = /^(\+33 ?[1-9] ?(\d{2} ?){4}|\+41 ?\d{2} ?\d{3} ?\d{2} ?\d{2}|(\d{2} ?){5}|(\d{3} ?){2}\d{2} ?\d{2})$/.test(`${control.value}`);
    return valid ? null : { 'invalidPhone': { value: control.value } };
  };
}
