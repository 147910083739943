import { ChangeDetectorRef, Component, Inject, inject } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { FoundItemFilters } from './found-item-filters.model';
import { ReferenceDataService } from '../../shared/services/reference-data.service';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { customDateValidator } from '../../shared/validators/date-validator';
import { DateMaskDirective } from '../../shared/directives/date-mask.directive';
import { MatDatepicker, MatDatepickerInput, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'app-found-item-filters-bottom-sheet',
  template: `
    <form [formGroup]="form" class="mt-4">
      <mat-form-field class="w-full" appearance="outline">
        <mat-label>Date</mat-label>
        <input matInput appDateMask [matDatepicker]="picker" (keydown.enter)="applyFilters()"
               formControlName="date">
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="w-full" appearance="outline">
        <mat-label>Catégorie</mat-label>
        <mat-select formControlName="category">
          @for (category of categories(); track category.id) {
            <mat-option [value]="category.id">{{ category.name }}</mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field class="w-full" appearance="outline">
        <mat-label>Couleur</mat-label>
        <mat-select formControlName="color">
          @for (color of colors(); track color) {
            <mat-option [value]="color.value">{{ color.label }}</mat-option>
          }
        </mat-select>
      </mat-form-field>
    </form>

    <div class="flex w-full gap-2 mb-2">
      <button mat-stroked-button class="w-full" color="primary" (click)="resetForm()">
        <mat-icon fontSet="material-icons-outlined">filter_alt_off</mat-icon>
        Effacer
      </button>
      <button mat-flat-button class="w-full" color="primary" [disabled]="form.invalid" (click)="applyFilters()">
        <mat-icon fontSet="material-icons-outlined">check_circle</mat-icon>
        Filtrer
      </button>
    </div>
  `,
  standalone: true,
  imports: [MatListModule, MatSelectModule, MatButton, MatIcon, ReactiveFormsModule, DateMaskDirective, MatDatepicker, MatDatepickerInput, MatDatepickerToggle, MatInput],
})
export class FoundItemFiltersBottomSheetComponent {
  private referenceDataService = inject(ReferenceDataService);
  private fb = inject(FormBuilder);
  private cdRef = inject(ChangeDetectorRef);

  categories = this.referenceDataService.categories;
  colors = this.referenceDataService.colors;

  form = this.fb.group({
    category: this.fb.control<number | null>(null),
    date: this.fb.control<Date | null>(null, [customDateValidator()]),
    color: this.fb.control<string | null>(null),
  });

  constructor(private bottomSheetRef: MatBottomSheetRef<FoundItemFiltersBottomSheetComponent>,
              @Inject(MAT_BOTTOM_SHEET_DATA) public data: FoundItemFilters) {
    this.form.patchValue({
      category: this.data.category ?? null,
      date: this.data.date ?? null,
      color: this.data.color ?? null,
    });
  }

  applyFilters() {
    this.bottomSheetRef.dismiss(this.form.getRawValue());
  }

  resetForm() {
    this.form.reset();
    this.bottomSheetRef.dismiss({});
  }
}
