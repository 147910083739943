import { HttpClient } from '@angular/common/http';
import { computed, inject, Injectable, Signal } from '@angular/core';
import { Category } from '../../models/category.model';
import { Observable, of } from 'rxjs';
import { Color } from '../../models/color.model';
import { environment } from '../../../environments/environment';
import { Line } from '../../models/line.model';
import { FoundItemStatus } from '../../models/found-item-status.enum';
import { ReferenceData } from '../../models/reference-data.model';
import { toSignal } from '@angular/core/rxjs-interop';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReferenceDataService {
  private httpClient = inject(HttpClient);

  colors: Signal<Color[] | undefined>;
  foundItemStatuses: Signal<ReferenceData<FoundItemStatus>[] | undefined>;
  filterableFoundItemStatuses = computed(() => {
    const status = this.foundItemStatuses()
    if (!status) return [];
    return status.filter((s) => {
      switch (s.value) {
        case FoundItemStatus.FOUND:
        case FoundItemStatus.ANALYZING:
        case FoundItemStatus.DEPOSITED:
        case FoundItemStatus.WAITING_CUSTOMER_VERIFICATION:
          return false;
        default:
          return true;
      }
    });
  });
  categories: Signal<Category[] | undefined>;
  lines: Signal<Line[] | undefined>;

  constructor() {
    this.colors = toSignal(this.findAllColors());
    this.foundItemStatuses = toSignal(this.findAllFoundItemStatus());
    this.categories = toSignal(this.findAllCategories());
    this.lines = toSignal(this.findLines());
  }

  findAllColors(): Observable<Color[]> {
    return this.httpClient.get<Color[]>(`${environment.apiUrl}/reference-data/colors`);
  }

  findAllFoundItemStatus(): Observable<ReferenceData<FoundItemStatus>[]> {
    return this.httpClient.get<ReferenceData<FoundItemStatus>[]>(`${environment.apiUrl}/reference-data/found-item-statuses`);
  }

  findAllCategories(): Observable<Category[]> {
    return this.httpClient.get<Category[]>(`${environment.apiUrl}/reference-data/categories`);
  }

  findLines(): Observable<Line[]> {
    return this.httpClient.get<Line[]>(`${environment.apiUrl}/reference-data/lines`);
  }
}
