import { inject, Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { FoundItem } from '../models/found-item.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { FoundItemFilters } from './found-item-table/found-item-filters.model';
import { ObjectHelper } from '../shared/object.helper';

@Injectable({
  providedIn: 'root'
})
export class FoundItemService {
  private http = inject(HttpClient);

  findAllAtPage(pageIndex: number, pageSize: number, filters: FoundItemFilters): Observable<{ items: FoundItem[], totalItems: number }> {
    const cleanFilters = ObjectHelper.removeUndefinedProperties(filters);
    console.log('cleanFilters:', cleanFilters);
    return this.http.get<{ items: FoundItem[], totalItems: number }>(`${environment.apiUrl}/found-item/light/paginated`, {
      params: {
        pageIndex,
        pageSize,
        ...cleanFilters
      }
    }).pipe(
      catchError((error) => {
        console.error('Error handling:', error);
        return throwError(() => new Error('Une erreur est survenue lors de la récupération des objets trouvés.'));
      })
    );
  }

}
