import { Directive, ElementRef, HostListener } from '@angular/core';

/**
 * Formats an input to follow a date pattern (dd.mm.yyyy), ensuring that input is numeric and includes necessary separators.
 */
@Directive({
  selector: '[appDateMask]',
  standalone: true
})
export class DateMaskDirective {

  constructor(private elementRef: ElementRef) {}

  @HostListener('input', ['$event'])
  handleInputEvent(event: Event) {
    // Remove any characters that are not digits
    let formattedInput = this.elementRef.nativeElement.value.replace(/[^0-9]/g, '');

    // Insert first dot after the day (2 digits)
    if (formattedInput.length > 2) {
      formattedInput = formattedInput.substring(0, 2) + '.' + formattedInput.substring(2);
    }

    // Insert second dot after the month (4 digits + 1 dot)
    if (formattedInput.length > 5) {
      formattedInput = formattedInput.substring(0, 5) + '.' + formattedInput.substring(5, 9);
    }

    // Update the input value with the formatted date
    this.elementRef.nativeElement.value = formattedInput;
  }
}
